import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import Layout from "../../components/Layout";
import { Link, StaticQuery } from "gatsby";

class EventsIndexPage extends React.Component {
  state = {
    today: new Date()
  };

  componentDidMount() {
    this.setState({
      today: new Date()
    });
  }
  render() {
    const data = this.props.data;
    const today = this.state.today;
    return (
      <Fragment>
        <div className="columns is-multiline">
          <div className="column is-12 has-text-centered">
            <h2 className="is-size-2 teal-bg is-inline-block">
              Upcoming Events
            </h2>
          </div>
          {data.allMarkdownRemark.nodes
            .filter(post => today < new Date(post.frontmatter.date))
            .map((post, i) => (
              <Fragment key={i}>
                <div className="column is-flex is-4">
                  <div
                    className="BlogRoll__img-container"
                    style={{
                      background: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.7)), url(${
                        post.frontmatter.image.childImageSharp.fluid.src
                      })`,
                      backgroundSize: `cover`,
                      flex: "auto"
                    }}
                  >
                    <span className="BlogRoll__date">
                      {post.frontmatter.date}
                    </span>
                    <div className="is-vcentered has-text-centered section is-medium has-text-white">
                      <h3 className="is-size-3 has-text-white has-subtitle">
                        {post.frontmatter.title}
                      </h3>
                      <h4 className="is-size-4 has-text-white">
                        {post.frontmatter.location}
                      </h4>

                      <Link
                        // to={post.fields.slug}
                        to="/contact"
                        className="btn btn-outline btn-outline-white"
                      >
                        Hire Us For This Event
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
        <div className="columns is-multiline">
          <div className="column is-12 has-text-centered">
            <h2 className="is-size-2 teal-bg is-inline-block">Past Events</h2>
          </div>
          {data.allMarkdownRemark.nodes
            .filter(post => today > new Date(post.frontmatter.date))
            .map((post, i) => (
              <Fragment key={i}>
                <div className="column is-flex is-4">
                  <div
                    className="BlogRoll__img-container"
                    style={{
                      background: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.7)), url(${
                        post.frontmatter.image.childImageSharp.fluid.src
                      })`,
                      backgroundSize: `cover`,
                      flex: "auto"
                    }}
                  >
                    <span className="BlogRoll__date">
                      {post.frontmatter.date}
                    </span>
                    <div className="is-vcentered has-text-centered section is-medium has-text-white">
                      <h3 className="is-size-3 has-text-white has-subtitle">
                        {post.frontmatter.title}
                      </h3>
                      <h4 className="is-size-4 has-text-white">
                        {post.frontmatter.location}
                      </h4>

                      <Link
                        // to={post.fields.slug}
                        to="/contact"
                        className="btn btn-outline btn-outline-white"
                      >
                        Hire Us For This Event
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      </Fragment>
    );
  }
}

// const EventsIndexPage = ({ data, today }) => (
//   <Fragment>
//     <div className="columns is-multiline">
//       <div className="column is-12 has-text-centered">
//         <h2 className="is-size-2 teal-bg is-inline-block">PAST</h2>
//       </div>
//       {data.allMarkdownRemark.nodes
//         .filter(post => today > new Date(post.frontmatter.date))
//         .map((post, i) => (
//           <Fragment key={i}>
//             <div className="column is-flex is-4">
//               <div
//                 className="BlogRoll__img-container"
//                 style={{
//                   background: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.7)), url(${
//                     post.frontmatter.image.childImageSharp.fluid.src
//                   })`,
//                   backgroundSize: `cover`,
//                   flex: "auto"
//                 }}
//               >
//                 <span className="BlogRoll__date">{post.frontmatter.date}</span>
//                 <div className="is-vcentered has-text-centered section is-medium has-text-white">
//                   <h3 className="is-size-3 has-text-white has-subtitle">
//                     {post.frontmatter.title}
//                   </h3>
//                   <h4 className="is-size-4 has-text-white">
//                     {post.frontmatter.location}
//                   </h4>

//                   <Link
//                     to={post.fields.slug}
//                     className="btn btn-outline btn-outline-white"
//                   >
//                     Read More
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </Fragment>
//         ))}
//     </div>
//     <div className="columns is-multiline">
//       <div className="column is-12 has-text-centered">
//         <h2 className="is-size-2 teal-bg is-inline-block">Upcoming</h2>
//       </div>
//       {data.allMarkdownRemark.nodes
//         .filter(post => today < new Date(post.frontmatter.date))
//         .map((post, i) => (
//           <Fragment key={i}>
//             <div className="column is-flex is-4">
//               <div
//                 className="BlogRoll__img-container"
//                 style={{
//                   background: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.7)), url(${
//                     post.frontmatter.image.childImageSharp.fluid.src
//                   })`,
//                   backgroundSize: `cover`,
//                   flex: "auto"
//                 }}
//               >
//                 <span className="BlogRoll__date">{post.frontmatter.date}</span>
//                 <div className="is-vcentered has-text-centered section is-medium has-text-white">
//                   <h3 className="is-size-3 has-text-white has-subtitle">
//                     {post.frontmatter.title}
//                   </h3>
//                   <h4 className="is-size-4 has-text-white">
//                     {post.frontmatter.location}
//                   </h4>

//                   <Link
//                     to={post.fields.slug}
//                     className="btn btn-outline btn-outline-white"
//                   >
//                     Read More
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </Fragment>
//         ))}
//     </div>
//   </Fragment>
// );

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "event-page" } } }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              name__
              description
              date(formatString: "MMM, DD, YYYY")
              description
              location
              image {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 1000, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader title={"Events"} paintColor={"green"} />
        <div id="events-splatter">
          <section className="section">
            <div className="container">
              <div className="content">
                <EventsIndexPage data={data} />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )}
  />
);
